import React from "react";
import "./Info.css";
import logo from "../assets/logo.png";

export const Info = () => {
  return (
    <div className="c1 c8 doc-content">
      <div>
        <p className="c0">
          <span
            style={{
              overflow: "hidden",
              display: "inline-block",
              margin: "0.00px 0.00px",
              border: "0.00px solid #000000",
              transform: "rotate(0.00rad) translateZ(0px)",
              WebkitTransform: "rotate(0.00rad) translateZ(0px)",
              width: "100%",
              textAlign: "center",
              height: "116.00px",
            }}
          >
            <img
              alt=""
              src={logo}
              style={{
                width: "300.00px",
                height: "auto",
                marginLeft: "0.00px",
                marginTop: "0.00px",
                transform: "rotate(0.00rad) translateZ(0px)",
                WebkitTransform: "rotate(0.00rad) translateZ(0px)",
              }}
              title=""
            />
          </span>
        </p>
      </div>
      <p className="c0">
        <span className="c3">What is Voter Maps</span>
        <br />
      </p>
      <p className="c0">
        <span>We are a non-partisan </span>
        <span>
          Tech-based non-profit (501c3) - like Linux or Wikipedia. Here’s what
          pisses us off: &nbsp;
        </span>
        <span className="c1 c5">
          Everyone in politics has our voter data, except us, the voters.
        </span>
        <span className="c1 c3">&nbsp;</span>
        <span className="c2 c1">
          As such, big-money ballot harvesting (called paid relational GOTV) is
          incredibly effective. &nbsp;It makes $ matter in politics and it
          shouldn’t.
        </span>
      </p>
      <p className="c0">
        <span className="c1">When they go low, we go nuclear. </span>
        <span className="c1">
          Votermaps.org frees voter data and wildly increases{" "}
        </span>
        <span className="c1 c5">overall </span>
        <span className="c1">
          turnout by making it accessible to everyone. &nbsp;During elections,
          Voter Maps will be updated nightly. You will find out who has
          requested a mail-in ballot, who has turned one in, voted early in
          person, or hasn’t voted yet. &nbsp;
          <br />
          <br />
        </span>
        <span className="c1 c3">Who Donates to Voter Maps?</span>
        <span className="c2 c1">
          <br />
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          State governments are expected to make grants for both GOTV and Voter
          Integrity (crowdsourced map inspection). Beyond that,{" "}
        </span>
        <span className="c1 c3">
          we want high-net-worth nonpartisan tech executives and bankers to
          write big checks. Your donations are anonymous.
        </span>
        <span className="c1">&nbsp; </span>
        <span className="c1 c6">
          Traditional political donors should continue working with parties and
          activist groups who can freely use Voter Maps via API, to make ballot
          chasing in every race SUPER AFFORDABLE
        </span>
        <span className="c1">.</span>
        <span className="c6 c1">&nbsp; </span>
        <span className="c1">
          <br />
          <br />
        </span>
        <span className="c1 c3">How does Voter Maps use funds?</span>
        <span className="c2 c1">
          <br />
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          Other than maintaining our database API of real-time voter status,
          80-90% of donations go to ad buys on X (and other socials if they will
          take them). &nbsp;We will pay to boost X user posts that use Votermaps
          to expose big-talking non-voters and investigate mail-in ballot fraud.
          &nbsp;These boosted posts will be seen statewide (millions of views).
          &nbsp;The result? 1) Real-time mail-in ballot fraud investigations 2)
          Everyone will know if you voted. &nbsp;20% of Americans lie about
          voting because they know nobody will find out. Now liars must choose.
          &nbsp; <br />
          <br />
        </span>
        <span className="c1 c3 c7">
          Privacy?
          <br />
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          We know this will be embarrassing to some lazy ass citizens. In past
          efforts like this, when political campaigns have tried to shame
          non-voters… &nbsp;red-faced citizens became angry at politicians.
          Voter Integrity and voter turn-out are too important. &nbsp;
        </span>
        <span className="c1 c3">We will deal with the angry ones,</span>
        <span className="c1">
          &nbsp;so the politicians and parties cannot be blamed. &nbsp;And if
          newly mortified voters promise they will vote, we will hide their
          Voter History (even though we have no legal obligation to do so).
          &nbsp;
        </span>
      </p>
    </div>
  );
};

export default Info;
