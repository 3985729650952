import pin_blue_50 from "./assets/pin_blue_100.png";
import pin_green_50 from "./assets/pin_green_100.png";
import pin_red_50 from "./assets/pin_red_100.png";
import pin_orange_50 from "./assets/pin_orange_100.png";
import map_key from "./assets/map_key.svg";
import search_voter from "./assets/search_voter.png";
import key from "./assets/key.svg";
import logo from "./assets/logo.png";
import "./App.css";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { Form, Icon, Popup, Button } from "semantic-ui-react";
import turfBboxPolygon from "@turf/bbox-polygon";
import { checkLatLng } from "./utils/helpers.js";
import useEventListener from "react-use-event-listener";
import { Info } from "./components/Info";
import { ModalContent, Modal } from "semantic-ui-react";

import React, { useRef, useEffect, useState } from "react";

mapboxgl.accessToken =
  "pk.eyJ1IjoiZ292d2hpeiIsImEiOiIxNTM0NGM2MjYwZmFjMWNiNGE3NTY4YTA5MTU4MjIyMiJ9.lPZaEuDk8-CRHWCB0ABdRg";

function App() {
  // const debug = false;
  const [statusFilter, setStatusFilter] = useState({
    RMB: true,
    SMB: true,
    VIP: true,
    YTV: true,
  });
  const [nameFilter, setNameFilter] = useState("");
  const [mounted, setMounted] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [filterExpression, setFilterExpression] = useState([]);
  const [isSearchApplied, setSearchApplied] = useState(false);
  const [showNameFilter, setShowNameFilter] = useState(false);
  const [selectedVoter, setSelectedVoter] = useState(null);
  const [popupVoter, setPopupVoter] = useState(null);
  const [clickCoords, setClickCoords] = useState(null);
  const [currentPopup, setCurrentPopup] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const mapContainer = useRef(null);
  const textCanvas = useRef(null);
  const downloadBtn = useRef(null);
  const map = useRef(null);
  const maxZoom = 22;
  const colors = ["#FCC41A", "#339AF0", "#21C930", "#FA5352"];

  useEffect(() => {
    if (map.current) return; // initialize map only once
    const hash = window.location.hash;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/govwhiz/clu7ezyx100d801qr3hxd3l4g",
      hash: true,
      maxZoom: maxZoom,
      projection: "mercator",
    });
    map.current.fitBounds(
      [
        [-88.001549, 24.453017],
        [-79.278405, 31.39666],
      ],
      { duration: 100 }
    );
    map.current.once("moveend", () => {
      const mapBounds = map.current.getBounds();
      const bboxSquarePolygon = turfBboxPolygon([
        mapBounds.getWest(),
        mapBounds.getSouth(),
        mapBounds.getEast(),
        mapBounds.getNorth(),
      ]);
      map.current.setMaxBounds([
        [bboxSquarePolygon.bbox[0], bboxSquarePolygon.bbox[1]],
        [bboxSquarePolygon.bbox[2], bboxSquarePolygon.bbox[3]],
      ]);
      if (hash?.split("/")?.length >= 3) {
        const parts = hash.split("/");
        const zoom = parseFloat(parts[0].slice(1));
        if (!isNaN(zoom) && zoom >= 0 && zoom <= maxZoom)
          map.current.setZoom(zoom);
        if (checkLatLng(parts[1], parts[2])) {
          map.current.setCenter([parts[2], parts[1]]);
        }
      }
    });
    map.current.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        marker: false,
        placeholder: "Search Address",
        bbox: [-88.001549, 24.453017, -79.278405, 31.39666],
        mapboxgl: mapboxgl,
      }),
      "top-left"
    );

    map.current.on("load", () => {
      // map.current.showTileBoundaries = true;
      loadImage(pin_blue_50, "pin_blue");
      loadImage(pin_red_50, "pin_red");
      loadImage(pin_orange_50, "pin_orange");
      loadImage(pin_green_50, "pin_green");
      loadLayer("govwhiz.clustered_florida1_Z7_z16_8M", "florida_voters_1");
      // after the GeoJSON data is loaded, update markers on the screen on every frame
      map.current.on("render", () => {
        if (!map.current.isSourceLoaded("govwhiz.clustered_florida1_Z7_z16_8M"))
          return;
        updateMarkers();
      });
      setMapLoaded(true);
    });

    map.current.on("zoomend", (e) => {
      setShowNameFilter(map.current.getZoom() >= 16);
    });
  });

  useEventListener("hide_history_clicked", (e) => {
    currentPopup.remove();
    if (clickCoords) {
      map.current.fire("click", {
        latLng: [clickCoords.lng, clickCoords.lat],
        point: map.current.project([clickCoords.lng, clickCoords.lat]),
        originalEvent: {},
      });
    }
  });

  useEventListener("info_clicked", (e) => {
    setModalOpen(true);
  });

  useEffect(() => {
    openPopupForSelectedVoter();
  }, []);

  useEffect(() => {
    if (!mapLoaded) return;
    try {
      const enabledStatuses = Object.keys(statusFilter).filter(
        (key) => statusFilter[key]
      );
      const statusFilters = enabledStatuses.map((st) => [
        "in",
        st,
        ["get", "electoral_status"],
      ]);
      const nameFilters = [
        [
          "in",
          nameFilter?.toLowerCase() ?? "",
          ["downcase", ["string", ["get", "full_name"]]],
        ],
        ["!", ["has", "clustered"]],
      ];
      const expression = ["all", ...nameFilters, ["any", ...statusFilters]];
      map.current.setFilter("govwhiz.clustered_florida1_Z7_z16_8M", expression);
      setFilterExpression(expression);
    } catch (e) {
      console.log(e);
    }
  }, [statusFilter, nameFilter, mapLoaded]);

  useEffect(() => {
    const { protocol, pathname, host, hash } = window.location;
    let params = new URL(document.location.toString()).searchParams;
    let nameParam = params.get("name");
    let voterParam = params.get("voter");
    let voterQuery, nameQuery;
    if (mounted) {
      voterQuery = selectedVoter ? `voter=${selectedVoter}` : null;
      nameQuery = nameFilter?.length > 0 ? `name=${nameFilter}` : null;
    } else {
      voterQuery = voterParam ? `voter=${voterParam}` : null;
      nameQuery = nameParam ? `name=${nameParam}` : null;
    }

    const query = [voterQuery, nameQuery].filter(Boolean).join("&");
    const newUrl = `${protocol}//${host}${pathname}?${query}${hash}`;
    window.history.pushState({}, "", newUrl);
    setMounted(true);
  }, [selectedVoter, nameFilter, mounted]);

  function openPopupForSelectedVoter() {
    let params = new URL(document.location.toString()).searchParams;
    let voterName = params.get("name");
    let selectedVoter = params.get("voter");
    setNameFilter(voterName);
    setSelectedVoter(selectedVoter);
    map.current.once("idle", function () {
      let features = map.current.queryRenderedFeatures({
        layers: ["govwhiz.clustered_florida1_Z7_z16_8M"],
      });
      const voter = features?.filter((f) => f.id === Number(selectedVoter));
      if (voter.length === 0) return;

      map.current.fire("click", {
        latLng: voter[0].geometry.coordinates,
        point: map.current.project(voter[0].geometry.coordinates),
        originalEvent: {},
      });
    });
  }
  function onStatusFilterChange(e, v) {
    const newFilters = { ...statusFilter, [v.value]: v.checked };
    setStatusFilter(newFilters);
  }

  function onNameFilterChange(v) {
    setNameFilter(v ?? "");
    setSearchApplied(false);
  }

  function searchVotersByName() {
    // const features = map.current.querySourceFeatures("govwhiz.clustered_florida1_Z7_z16_8M", {
    //   sourceLayer: "ohio_voters",
    //   filter: filterExpression,
    // });
    setSearchApplied(true);
  }

  function loadImage(img, name) {
    map.current.loadImage(
      img, // Path to your image here
      (error, image) => {
        map.current.addImage(name, image);
      }
    );
  }

  function loadLayer(id, layer) {
    map.current.addSource(id, {
      type: "vector",
      // Use any Mapbox-hosted tileset using its tileset id.
      // Learn more about where to find a tileset id:
      // https://docs.mapbox.com/help/glossary/tileset-id/
      url: `mapbox://${id}`,
    });

    map.current.addLayer({
      id,
      type: "symbol",
      source: id,
      "source-layer": layer,
      filter: ["!", ["has", "point_count"]],
      minzoom: 16,
      layout: {
        "icon-image": [
          "match",
          ["get", "electoral_status"],
          "RMB",
          "pin_green",
          "SMB",
          "pin_blue",
          "VIP",
          "pin_orange",
          "YTV",
          "pin_red",
          "",
        ],
        "icon-size": 0.35,
        "icon-anchor": "bottom",
        "icon-allow-overlap": true,
      },
    });

    map.current.addLayer({
      id: `${id}_cluster`,
      source: id,
      type: "circle",
      "source-layer": layer,
      filter: ["has", "point_count"],
      maxZoom: 15,
      paint: {
        "circle-opacity": 0,
        "circle-radius": 0,
      },
    });

    // When a click event occurs on a feature in the places layer, open a popup at the
    // location of the feature, with description HTML from its properties.
    map.current.on("click", id, (e) => {
      map.current.flyTo({
        center: e.features[0].geometry.coordinates,
      });
      // Copy coordinates array.
      const partyAffiliation = {
        BPP: "Boricua Party",
        LPF: "Libertarian Party of Florida",
        CPP: "Coalition With a Purpose Party",
        CSV: "Conservative Party of Florida",
        PSL: "Party for Socialism and Liberation",
        DEM: "Florida Democratic Party",
        FFP: "Florida Forward Party",
        ECO: "Ecology Party of Florida",
        NLP: "No Labels Party of Florida",
        CPF: "Constitution Party of Florida",
        GRE: "Green Party of Florida",
        PEO: "People's Party",
        REP: "Republican Party",
        NPA: "No Party Affiliation",
        IND: "Independent Party of Florida ",
      };
      // Copy coordinates array.
      const statusMapping = {
        YTV: "Yet to Vote",
        SMB: "Voted by Mail",
        RMB: "Request Ballot by Mail",
        VIP: "Voted Early in Person",
      };

      const feature = e.features[0];
      const props = feature.properties;
      const coordinates = feature.geometry.coordinates.slice();
      const fullName = props.full_name?.toUpperCase();
      const sosVoterID = feature.id;
      const fullAddress = props.full_address;
      const status = statusMapping[props.electoral_status] ?? "";
      const affiliation = partyAffiliation[props.party_affiliation];
      const votingHistory = {};
      const shouldHideHistory =
        localStorage.getItem(`promise_voter_${feature.id}`) === "true";
      let votingHistoryHTML = "";
      let affiliationHTML = affiliation ? `<div>${affiliation}</div>` : "";

      const primaryHistoryKey = Object.keys(props).filter(
        (p) => p.indexOf("pri_") === 0
      );
      const generalHistoryKey = Object.keys(props).filter(
        (p) => p.indexOf("gen_") === 0
      );
      const presHistoryKey = Object.keys(props).filter(
        (p) => p.indexOf("ppp_") === 0
      );
      for (const k of primaryHistoryKey) {
        const year = k.split("_").pop();
        if (!votingHistory[`${year}`]) {
          votingHistory[`${year}`] = {};
        }
        votingHistory[`${year}`]["primary"] = props[k];
      }
      for (const k of generalHistoryKey) {
        const year = k.split("_").pop();
        if (!votingHistory[`${year}`]) {
          votingHistory[`${year}`] = {};
        }
        votingHistory[`${year}`]["general"] = props[k];
      }
      for (const k of presHistoryKey) {
        const year = k.split("_").pop();
        if (!votingHistory[`${year}`]) {
          votingHistory[`${year}`] = {};
        }
        votingHistory[`${year}`]["presidential"] = props[k];
      }
      Object.keys(votingHistory).forEach((year) => {
        votingHistoryHTML += `<tr>
          <th>${year}</th>
          <td>${votingHistory[year]["primary"] || "-"}</td>
          <td>${votingHistory[year]["general"] || "-"}</td>
          <td>${votingHistory[year]["presidential"] || "-"}</td>
        </tr>`;
      });

      const popup = new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(
          `<table style="width:100%; text-align: left; min-width: 200px;">
              <tr>
                <td style="text-align: center;"  colspan="2">
                 <img style="width:150px;" src='${logo}'/>
                </td>
              </tr>
              <tr>
                <td><div>${sosVoterID}</div>${affiliationHTML}</td>
              </tr>
              <tr>
                <th>
                  <div>${fullName}</div>
                  <div>${fullAddress}</div>
                </th>
              </tr>
              <tr>
                <td>
                  ${status}
                </td>
                <td>
                  <span 
                    style="color: blue; cursor: pointer; font-weight:bold"
                    onclick="(function(){  window.dispatchEvent(new Event('info_clicked')); })()">
                    Info
                  </span>
                </td>
              </tr>
              <tr>
                <td style="text-align: center;">
                  <label>
                    <input ${
                      shouldHideHistory ? "checked" : ""
                    } style="vertical-align: middle;" type="checkbox" id="promise_vote" name="promise"
                      onclick="(function(){ 
                        localStorage.setItem('promise_voter_${
                          feature.id
                        }', ${!shouldHideHistory});
                        window.dispatchEvent(new Event('hide_history_clicked'));
                      })()">
                    <span style="vertical-align: middle;">hide/promise to vote</span>
                  </label>
                </td>
              </tr>
              <tr>
                <table style="width: 100%; border: 1px solid #f0f0f0; margin-top:8px; ${
                  votingHistoryHTML && !shouldHideHistory
                    ? ""
                    : "; display: none;"
                }">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Pri</th>
                      <th>Gen</th>
                      <th>POTUS Pri</th>
                    </tr>
                  </thead>
                  <tbody>
                    ${votingHistoryHTML}
                  </tbody>
                </table>
              </tr>
            </table>`
        )
        .addTo(map.current);

      setSelectedVoter(sosVoterID);
      setPopupVoter(feature);
      setCurrentPopup(popup);
      setClickCoords(e.lngLat);
    });

    // Change the cursor to a pointer when the mouse is over the places layer.
    map.current.on("mouseenter", id, () => {
      map.current.getCanvas().style.cursor = "pointer";
    });

    // Change it back to a pointer when it leaves.
    map.current.on("mouseleave", id, () => {
      map.current.getCanvas().style.cursor = "";
    });
  }

  function onClusterClick(feature) {
    const center: LngLatLike = feature.geometry.coordinates;
    map.current.easeTo({
      center: center,
      zoom: map.current.getZoom() + 2,
    });
  }

  // objects for caching and keeping track of HTML marker objects (for performance)
  const markers = {};
  const textWidths = {};
  let markersOnScreen = {};

  function getTextWidth(text, font) {
    if (textWidths[text]) return textWidths[text];
    const context = textCanvas.current.getContext("2d");
    context.font = "20px sans-serif" || getComputedStyle(document.body).font;
    const width = context.measureText(text).width;
    textWidths[text] = width;
    return width;
  }

  function updateMarkers() {
    const newMarkers = {};

    let features = map.current.queryRenderedFeatures({
      layers: ["govwhiz.clustered_florida1_Z7_z16_8M_cluster"],
    });
    let ids = features.map((o) => o.id);

    features = features.filter(({ id }, index) => !ids.includes(id, index + 1));
    // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
    // and add it to the map if it's not there already
    for (const feature of features) {
      const coords = feature.geometry.coordinates;
      const props = feature.properties;

      if (!props.clustered) {
        continue;
      }
      const id = feature.id;

      let marker = markers[id];
      const isMarkerInvalidated =
        marker && marker.feature.properties.point_count !== props.point_count;
      if (!marker || isMarkerInvalidated) {
        if (isMarkerInvalidated) {
          marker.remove();
          delete markersOnScreen[id];
        }
        const el = createDonutChart(props);
        marker = markers[id] = new mapboxgl.Marker({
          element: el,
        }).setLngLat(coords);
        marker.feature = feature;
        marker.getElement().addEventListener("click", (e) => {
          onClusterClick(marker.feature);
        });
      }
      newMarkers[id] = marker;

      if (!markersOnScreen[id]) marker.addTo(map.current);
    }
    // for every marker we've added previously, remove those that are no longer visible
    for (const id in markersOnScreen) {
      if (!newMarkers[id]) markersOnScreen[id].remove();
    }
    markersOnScreen = newMarkers;
  }

  // code for creating an SVG donut chart from feature properties
  function createDonutChart(props) {
    const offsets = [];
    const counts = [props.vip, props.smb, props.rmb, props.ytv];
    let total = 0;
    for (const count of counts) {
      offsets.push(total);
      total += count;
    }
    const fontSize = 18;
    const r = getTextWidth(props.point_count_abbreviated);
    const r0 = Math.round(r * 0.6);
    const w = r * 2;

    let html = `<div>
        <svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block">`;

    for (let i = 0; i < counts.length; i++) {
      html += donutSegment(
        offsets[i] / total,
        (offsets[i] + counts[i]) / total,
        r,
        r0,
        colors[i]
      );
    }
    html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="white" />
        <text dominant-baseline="central" transform="translate(${r}, ${r})">
            ${props.point_count_abbreviated.toLocaleString()}
        </text>
        </svg>
        </div>`;

    const el = document.createElement("div");
    el.innerHTML = html;
    return el.firstChild;
  }

  function donutSegment(start, end, r, r0, color) {
    if (end - start === 1) end -= 0.00001;
    const a0 = 2 * Math.PI * (start - 0.25);
    const a1 = 2 * Math.PI * (end - 0.25);
    const x0 = Math.cos(a0),
      y0 = Math.sin(a0);
    const x1 = Math.cos(a1),
      y1 = Math.sin(a1);
    const largeArc = end - start > 0.5 ? 1 : 0;

    // draw an SVG path
    return `<path d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${
      r + r * y0
    } A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${r + r * y1} L ${
      r + r0 * x1
    } ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${
      r + r0 * y0
    }" fill="${color}" />`;
  }

  function downloadData() {
    let features = map.current.queryRenderedFeatures({
      layers: ["govwhiz.clustered_florida1_Z7_z16_8M"],
    });
    console.log(
      JSON.stringify({
        type: "FeatureCollection",
        features: features,
      })
    );
  }

  return (
    <div className="App">
      {/* <Button ref={downloadBtn} className="map-button" onClick={downloadData}>
        Download
      </Button> */}
      <canvas id="text-canvas" ref={textCanvas}></canvas>
      <div id="map-container" ref={mapContainer} className="map-container" />
      <div className="filters">
        <Popup
          hoverable
          position="top right"
          trigger={
            <Button
              icon
              style={{
                backgroundColor: "white",
              }}
            >
              <img src={key} width="20" height="20" alt="filter_voter" />
            </Button>
          }
        >
          <img src={map_key} width="340" height="240" alt="map_key" />
        </Popup>

        {showNameFilter && (
          <Popup
            basic
            className="search-popup"
            on="click"
            position="left center"
            trigger={
              <Button
                icon
                style={{
                  backgroundColor: nameFilter ? "#d1ffbd" : "white",
                }}
              >
                <img
                  src={search_voter}
                  width="20"
                  height="20"
                  alt="search_voter"
                />
              </Button>
            }
          >
            <Form v-i className="filter-input" onSubmit={searchVotersByName}>
              <Form.Input
                icon
                placeholder="Search Voter Name"
                value={nameFilter}
                onChange={(e) => onNameFilterChange(e.target.value)}
              >
                <input />
                {nameFilter ? (
                  <Icon
                    name="delete"
                    link
                    onClick={() => onNameFilterChange("")}
                  />
                ) : (
                  <Icon name="search" link onClick={searchVotersByName} />
                )}
              </Form.Input>
            </Form>
          </Popup>
        )}
      </div>

      <Modal
        closeIcon
        open={isModalOpen}
        size="small"
        onClose={() => setModalOpen(false)}
      >
        <ModalContent scrolling>
          <Info></Info>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default App;
